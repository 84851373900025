<template>
	<div data-component="lesson-action-list">
		<action-list
			:actions="getActionList"
			:isWaiting="isWaiting"
			@actionClick="onActionClick"
		/>
		<portal
			to="modal"
			v-if="lessonNotesAreVisible"
		>
			<modal-inner
				@close="toggleLessonNotesAreVisible"
				heading="Lesson notes"
			>
				<div
					data-element="lesson-notes"
					v-html="lesson.notes"
				/>
			</modal-inner>
		</portal>
		<portal
			to="modal"
			v-if="activitiesModalIsVisible"
		>
			<modal-inner
				@close="toggleActivitiesModalIsVisible"
				heading="Edit activities"
			>
				<activities-modal
					:lesson="lesson"
					@updateActivitiesOrder="onUpdateActivitiesOrder"
				/>
			</modal-inner>
		</portal>
		<portal
			to="modal"
			v-if="createResourceModalIsVisible"
		>
			<modal-inner
				@close="closeCreateResourceModal"
				heading="Add resource"
			>
				<create-resource-modal
					:lesson="lesson"
					@close="closeCreateResourceModal"
				/>
			</modal-inner>
		</portal>
		<portal
			to="modal"
			v-if="editResourceModalIsVisible"
		>
			<modal-inner
				@close="closeEditResourceModal"
				heading="Edit resource"
			>
				<edit-resource-modal
					:lesson="lesson"
					:editableMediaId="editableMediaId"
					@close="closeEditResourceModal"
				/>
			</modal-inner>
		</portal>
		<portal
			to="modal"
			v-if="resourcesEditModalIsVisible"
		>
			<modal-inner
				@close="toggleResourcesEditModalIsVisible"
				heading="Edit resources"
			>
				<resources-edit-modal
					:lesson="lesson"
					@createMedia="openCreateResourceModal"
					@editMedia="openEditResourceModal"
				/>
			</modal-inner>
		</portal>
		<portal
			to="modal"
			v-if="recordingEditModalIsVisible"
		>
			<modal-inner
				@close="toggleRecordingEditModalIsVisible"
				heading="Edit recording"
			>
				<!-- lesson recording edit modal -->
				<recording-edit-modal
					:lesson="lesson"
					@close="toggleRecordingEditModalIsVisible"
				/>
			</modal-inner>
		</portal>
	</div>
</template>

<script>

	import ActionList from '@/components/ui/ActionList';
	import ModalInner from '@/components/ui/modal/ModalInner';
	import ActivitiesModal from '@/components/lessons/ActivitiesModal';
	import ResourcesEditModal from '@/components/lessons/ResourcesEditModal';
	import CreateResourceModal from '@/components/lessons/CreateResourceModal';
	import EditResourceModal from '@/components/lessons/EditResourceModal';
	import RecordingEditModal from '@/components/lessons/RecordingEditModal';
	import actionClick from '@/mixins/actionClick';
	import routeParams from '@/mixins/routeParams';
	import api from '@/services/api';

	export default {
		components: {
			ActionList,
			ModalInner,
			ActivitiesModal,
			CreateResourceModal,
			EditResourceModal,
			ResourcesEditModal,
			RecordingEditModal
		},
		mixins: [actionClick, routeParams],
		props: {
			lesson: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({
			lessonNotesAreVisible: false,
			activitiesModalIsVisible: false,
			createResourceModalIsVisible: false,
			editResourceModalIsVisible: false,
			resourcesEditModalIsVisible: false,
			recordingEditModalIsVisible: false,
			isWaiting:  undefined,
			editableMediaId: undefined
		}),
		computed: {
			getShowEditLesson () {
				if (this.getContext === 'browseCourses' ||
						this.getContext === 'myCourses') {
					return false;
				}
				return true;
			},
			getShowEditRecording () {
				if (this.getContext === 'browseCourses' ||
						this.getContext === 'myCourses') {
					return false;
				}
				if (!this.lesson?.live ||
						!this?.lesson?.schedule?.willUpload ||
						!this?.lesson?.schedule?.endedAt) {
					return false;
				}
				return true;
			},
			getShowViewLessonNotes () {
				if (this.getContext !== 'teaching' &&
						this.getContext !== 'admin') {
					return false;
				}
				if (!this.lesson?.notes?.length) {
					return false;
				}
				return true;
			},
			getShowDuplicateLesson () {
				return this.getContext === 'teaching';
			},
			getActionList () {
				return [
					(this.getShowEditLesson ?
						{
							text: 'Edit lesson',
							description: 'Edit details like title, description and time, and manage recordings',
							route: `/${this.getContextSlug}/courses/${this.getCourseId}/lessons/${this.getLessonId}/edit`
						} : false
					),
					(this.getShowViewLessonNotes ?
						{
							text: 'View lesson notes',
							description: 'See notes used to prepare for the lesson',
							actionId: 'toggleLessonNotesAreVisible'
						} : false
					),
					(this.getShowEditLesson ?
						{
							text: 'Edit resources',
							description: 'Upload, edit or remove resources',
							actionId: 'toggleResourcesEditModalIsVisible'
						} : false
					),
					(this.getContext === 'teaching' ?
						{
							text: 'Edit study deals',
							description: 'Add or remove study deals',
							route: `/${this.getContextSlug}/courses/${this.getCourseId}/lessons/${this.getLessonId}/deals`
						} : false
					),
					(this.getShowEditRecording ?
						{
							text: 'Edit recording',
							description: 'Upload, edit or remove a recording',
							actionId: 'toggleRecordingEditModalIsVisible'
						} : false
					),
					(this.getShowEditLesson ?
						{
							text: 'Edit activities',
							description: this.getContext === 'admin' ? 'Edit and reorder activities' : 'Add, edit and reorder activities',
							actionId: 'toggleActivitiesModalIsVisible'
						} : false
					),
					(this.getShowDuplicateLesson ?
						{
							text: 'Duplicate',
							actionId: 'duplicateLesson',
							btnId: 'duplicateLesson'
						} : false
					)
				].filter(Boolean);
			}
		},
		methods: {
			toggleLessonNotesAreVisible () {
				this.lessonNotesAreVisible = !this.lessonNotesAreVisible;
			},
			toggleActivitiesModalIsVisible () {
				this.activitiesModalIsVisible = !this.activitiesModalIsVisible;
			},
			openCreateResourceModal () {
				this.resourcesEditModalIsVisible = false;
				this.createResourceModalIsVisible = true;
			},
			closeCreateResourceModal () {
				this.createResourceModalIsVisible = false;
				this.resourcesEditModalIsVisible = true;
			},
			openEditResourceModal (media) {
				this.editableMediaId = media.id;
				this.resourcesEditModalIsVisible = false;
				this.editResourceModalIsVisible = true;
			},
			closeEditResourceModal () {
				this.editResourceModalIsVisible = false;
				this.resourcesEditModalIsVisible = true;
				this.editableMediaId = undefined;
			},
			toggleResourcesEditModalIsVisible () {
				this.resourcesEditModalIsVisible = !this.resourcesEditModalIsVisible;
			},
			toggleRecordingEditModalIsVisible () {
				this.recordingEditModalIsVisible = !this.recordingEditModalIsVisible;
			},
			onUpdateActivitiesOrder (activities) {
				this.lesson.activities = activities;
			},
			async duplicateLesson (activities) {
				this.isWaiting = 'duplicateLesson';
				const response = await api.teaching.duplicateLessonById({
					lessonId: this.lesson.id
				});
				if (!response?.id) {
					this.isWaiting = undefined;
					return;
				}
				this.isWaiting = undefined;
				await this.$router.push(`/teaching/courses/${this.getCourseId}/lessons/${response.id}`);
				this.$store.commit('ui/showNotification', {
					notification: 'The lesson has been duplicated'
				});
			}
		}
	};

</script>

<style lang="scss" scoped>
	::v-deep [data-element='lesson-notes'] {
		font-size:rem(18);
		@include rich-text-content;
		@include rich-text-suits;
	}
	[data-component='lesson-action-list'] {
    margin-bottom: 36px;

    [data-component=action-list] {
      display: flex;
      flex-direction: column;
      gap: 6px;
    }
	}
</style>
