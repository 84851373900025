<template>
	<div>
		<lesson-view
			:lesson="lesson"
		/>
	</div>
</template>

<script>

	import LessonView   from '@/components/lessons/LessonView';
	import routeParams  from '@/mixins/routeParams';
	import api          from '@/services/api';

	export default {
		layout: 'v2default',
		metaInfo () {
			if (!this.getLessonTitle) {
				return false;
			}
			return {
				title: this.getLessonTitle
			};
		},
		components: {
			LessonView
		},
		mixins: [routeParams],
		data: () => ({
			lesson: {}
		}),
		computed: {
			getLessonTitle () {
				return this.lesson?.title;
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: this.getCoursesRoute,
						text: 'Courses'
					},
					{
						path: this.getCourseRoute,
						text: this.lesson?.courseTitle
					},
					{
						path: this.getLessonRoute,
						text: this.getLessonTitle
					}
				];
			}
		},
		created () {
			this.setLesson();
		},
		methods: {
			async setLesson () {
				const lesson = await api.admin.getLessonById({
					lessonId: this.getLessonId
				});
				if (!lesson) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.lesson = lesson;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};

</script>
