<template>
	<div data-component="lesson-deals">
		<div data-element="deals-list">
			<study-deal-item
				v-for="(deal, index) in getLessonDeals"
				:key="deal.id"
				:deal="deal"
				:index="index"
				:isDisabled="isDealDisabled(index)"
				@onPlayDeal="handlePlayDeal(index)"
				@onShowDealRecording="handleShowDealRecording"
			/>
		</div>
		<portal
			to="modal"
			v-if="previewIsVisible"
		>
			<modal-inner
				@close="closePreview"
				heading="Preview deals"
			>
				<deal-summaries
					:deals="getLessonDeals"
					:currentIndex="gameStateIndex"
				/>
			</modal-inner>
		</portal>
		<portal
			to="fullscreenModal"
			v-if="simulatorIsVisible"
		>
			<simulator
				:mode="getSimulatorMode"
				:gameIds="getGameIds"
				:gameStates="getGameStates"
				:gameStateIndex="gameStateIndex"
				:playersPositions="getPlayersPositions"
				:bidExplanationsList="getBidExplanationsList"
				:bidHintsList="getBidHintsList"
				:declarerHintsList="getDeclarerHintsList"
				:openingLeadsList="getOpeningLeadsList"
				:claimParametersList="getClaimParametersList"
				@close="closeSimulator"
			/>
		</portal>
		<portal
			to="modal"
			v-if="dealRecordingIsVisible"
		>
			<modal-inner
				@close="closeDealRecording"
				heading="Deal run-through"
			>
				<div data-element="deal-recording">
					<video-viewer :src="dealRecordingSrc" />
					<actions
						:actions="getDealRecordingActions"
						@actionClick="onActionClick"
					/>
				</div>
			</modal-inner>
		</portal>
	</div>
</template>

<script>

	import StudyDealItem from '@/components/lessons/StudyDealItem';
	import ModalInner from '@/components/ui/modal/ModalInner';
	import DealSummaries from '@/components/diagrams/DealSummaries';
	import VideoViewer from '@/components/uploads/VideoViewer';
	import Actions from '@/components/ui/Actions';
	import routeParams from '@/mixins/routeParams';
	import actionClick from '@/mixins/actionClick';
	import { SIMULATOR_MODES } from '@/consts';

	export default {
		components: {
			StudyDealItem,
			ModalInner,
			DealSummaries,
			VideoViewer,
			Actions,
			Simulator: () => import('@/components/simulator/Simulator')
		},
		mixins: [routeParams, actionClick],
		props: {
			lesson: {
				type: Object,
				default: undefined
			}
		},
		data: () => ({
			previewIsVisible: false,
			simulatorIsVisible: false,
			gameStateIndex: undefined,
			dealRecordingIsVisible: false,
			dealRecordingSrc: undefined
		}),
		computed: {
			getShowPreview () {
				return !(this.getContext === 'browseCourses' ||
					this.getContext === 'myCourses');
			},
			getSimulatorMode () {
				return this.getShowPreview ? SIMULATOR_MODES.PREVIEW : SIMULATOR_MODES.STUDY;
			},
			getLessonDeals () {
				return this.lesson.deals || [];
			},
			getGameIds () {
				return this.getLessonDeals.map(deal => deal.id);
			},
			getGameStates () {
				return this.getLessonDeals?.map((deal) => {
					return deal.deal;
				});
			},
			getPlayersPositions () {
				return this.getLessonDeals.map(deal => deal.playerPosition);
			},
			getBidExplanationsList () {
				return this.getLessonDeals.map(deal => deal.bidExplanations);
			},
			getBidHintsList () {
				return this.getLessonDeals.map(deal => deal.bidHints);
			},
			getDeclarerHintsList () {
				return this.getLessonDeals.map(deal => deal.declarerHint);
			},
			getOpeningLeadsList () {
				return this.getLessonDeals.map(deal => deal.openingLead);
			},
			getClaimParametersList () {
				return this.getLessonDeals.map(deal => deal.claimParameters);
			},
			getDealRecordingActions () {
				return {
					primary: [],
					secondary: [
						{
							text: 'Close',
							actionId: 'closeDealRecording'
						}
					]
				};
			},
			getGameIsComplete () {
				return this.$store.getters['simulator/getGameIsComplete'];
			}
		},
		watch: {
			getGameIsComplete (value, oldValue) {
				if (value && !oldValue) {
					this.$emit('reloadLesson');
				}
			}
		},
		methods: {
			isDealDisabled (index) {
				if (index > 0) {
					const prevIndex = index - 1;
					const prevDeal = this.getLessonDeals[prevIndex];
					return !prevDeal?.isComplete;
				}
				return false;
			},
			resetGameStateIndex () {
				this.gameStateIndex = undefined;
			},
			openPreview () {
				this.previewIsVisible = true;
			},
			async openSimulator (index) {
				const gameMode = this.$store.getters['gameEngine/getGameMode']({ legacyMode: this.getSimulatorMode });
				const roomToken = await this.$store.dispatch('gameEngine/getRoomToken', {
					mode: gameMode,
					dealIds: this.getGameIds.slice(index),
					exitRedirectUrl: window.location.href,
					dealRecordingEnabled: [],
					metadata: {
						lessonId: this.lesson?.id
					}
				});
				await this.$store.dispatch('gameEngine/openGameEngine', { roomToken, newTab: false });
			},
			closePreview () {
				this.previewIsVisible = false;
				this.resetGameStateIndex();
			},
			closeSimulator () {
				this.simulatorIsVisible = false;
				this.resetGameStateIndex();
			},
			handlePlayDeal (index) {
				this.gameStateIndex = index;
				if (this.getShowPreview) {
					this.openPreview();
				} else {
					this.openSimulator(index);
				}
			},
			handleShowDealRecording (src) {
				this.dealRecordingSrc = src;
				this.dealRecordingIsVisible = true;
			},
			closeDealRecording () {
				this.dealRecordingIsVisible = false;
				this.dealRecordingSrc = undefined;
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-element='deal-recording'] {

		[data-component='actions'] {
			padding-bottom: 0;
		}
	}

</style>
