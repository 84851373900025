<template>
	<div class="page container">
		<v2-back-btn :route="backButtonRoute" />
		<div class="page__info" v-if="lesson">
			<div class="page__info--title">
				{{ lesson.title }}
			</div>
			<div class="page__info--subtitle">
				Lesson: {{ lesson.description }}
			</div>
			<video-viewer
				v-if="getVideoSrc"
				:src="getVideoSrc"
			/>

			<div
				data-element="main-cta"
				v-if="getShowMainCta"
			>
				<V2Button
					v-if="getZoomLink"
					:url="getZoomLink"
					variant="outline"
					fit
					size="l"
				>
					View lesson on Zoom
				</V2Button>

				<V2Button
					v-if="getZoomDownloadLink"
					:url="getZoomDownloadLink"
					variant="outline"
					fit
					size="l"
				>
					View replay on Zoom
				</V2Button>
			</div>
		</div>

		<div class="separator" />
		<div class="lesson content">
			<div class="main">
				<div class="lesson__activities" v-if="activityList.length">
					<div class="lesson__activities--content">
						<v2-activity-card
							v-for="activity in activityList"
							:key="activity.activityGroupNumb"
							:activity="activity"
							:liveUserMode="lesson.live && getContext === 'myCourses'"
							:isLive="lesson.live"
							:teacherMode="getContext !== 'myCourses'"
						/>
					</div>
				</div>
				<lesson-action-groups
					v-if="getLessonIsLoaded"
					:lesson="lesson"
				/>
			</div>
			<div class="side">
				<div class="lesson__breakdown">
					<div class="lesson__breakdown--title" v-if="lessonLabels.length">
						<icon icon="v2-book-round" colour="transparent" />
						Lesson Breakdown
					</div>
					<div class="lesson__breakdown--tags" v-if="lessonLabels.length">
						<div
							class="lesson__breakdown--tags-wrapper"
							v-for="(label, idx) in lessonLabels"
							:key="label.type"
						>
							<div class="tag">
								<icon :icon="label.icon" colour="medium-navy" />
								{{ label.title }}
							</div>
							<div v-if="idx !== lessonLabels.length - 1" class="dot" />
						</div>
					</div>

					<div class="lesson__breakdown--title" v-if="getShowLessonDeals">
						<icon icon="v2-settings" colour="transparent" />
						Study Deals
					</div>

					<div class="lesson__breakdown--controlls" v-if="getShowLessonDeals">
						<lesson-deals
							:lesson="lesson"
							@reloadLesson="handleReloadLesson"
						/>
					</div>

					<div class="lesson__breakdown--title" v-if="getContext !== 'myCourses'">
						<icon icon="v2-settings" colour="transparent" />
						Edit
					</div>

					<div class="lesson__breakdown--controlls">
						<lesson-action-list
							:lesson="lesson"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import VideoViewer         from '@/components/uploads/VideoViewer';
	import LessonDeals         from '@/components/lessons/LessonDeals';
	import LessonActionGroups  from '@/components/lessons/LessonActionGroups';
	import LessonActionList    from '@/components/lessons/LessonActionList';
	import actionClick         from '@/mixins/actionClick';
	import routeParams         from '@/mixins/routeParams';
	import dayjs               from 'dayjs';

	import V2BackBtn from '@/components/v2/ui/V2BackBtn';
	import V2Button           from '@/components/v2/ui/V2Button';
	import V2ActivityCard from '@/components/v2/activity/V2ActivityCard';
	import Icon from '@/components/ui/Icon';

	export default {
		components: {
			VideoViewer,
			LessonDeals,
			LessonActionGroups,
			LessonActionList,

			V2BackBtn,
			V2Button,
			V2ActivityCard,
			Icon
		},
		mixins: [actionClick, routeParams],
		props: {
			lesson: {
				type: Object,
				default: undefined
			}
		},
		computed: {

			backButtonRoute () {
        if (this.getContext === 'myCourses') {
          return `/my-courses/${this.getCourseId}/`;
        }

				return `/${this.getContext}/courses/${this.getCourseId}/`;
			},

			lessonLabels () {
				const labels = this.getGroupsLabels();

				return labels.map((item) => this.parseToLessonLabel(item));
			},

			activityList () {
        if (!this.lesson?.activities) {
          return [];
        }

        return this.lesson.activities.reduce((prev, curr) => {
          const prevIdx = prev.length;

          if (curr.type === 'quiz') {
            if (prevIdx === 0 || prev[prevIdx - 1].type !== 'quiz') {
              prev.push({
                title: 'Quiz',
                type: 'quiz',
                activityGroupNumb: prev.length + 1,
                item: [{ ...curr, activityGroupItemNumb: 1 }]
              });
            } else {
              prev[prevIdx - 1].item.push({
                ...curr,
                activityGroupItemNumb: prev[prevIdx - 1].item.length + 1
              });
            }
          }

          if (curr.type === 'video') {
            prev.push({
              title: 'Video',
              name: curr.title,
              type: 'video',
              activityGroupNumb: prev.length + 1,
              item: [curr]
            });
          }

          if (curr.type === 'embeddedPresentation') {
            prev.push({
              title: 'Presentation',
              name: curr.title,
              type: 'embeddedPresentation',
              activityGroupNumb: prev.length + 1,
              item: [curr]
            });
          }

          if (curr.type === 'deal') {
            prev.push({
              title: 'Deal to Play',
              name: curr.title,
              type: 'deal',
              activityGroupNumb: prev.length + 1,
              item: [curr]
            });
          }

          if (curr.type === 'titlePage') {
            prev.push({
              title: 'Handouts',
              name: curr.title,
              type: 'titlePage',
              activityGroupNumb: prev.length + 1,
              item: [curr]
            });
          }

          return prev;
        }, []);
			},

			getLessonIsLoaded () {
				return this.lesson?.id !== undefined;
			},
			getIsLive () {
				return this.lesson?.live;
			},
			getCourseTitle () {
				return this.lesson?.courseTitle;
			},
			getLessonTitle () {
				return this.lesson?.title;
			},
			getDescription () {
				return this.lesson?.description;
			},
			getRecordingMedia () {
				return this.lesson?.media?.filter((media) => {
					return media.config.type === 'recording';
				});
			},
			getVideoSrc () {
				if (!this.getRecordingMedia?.[0]?.url) {
					return false;
				}
				return this.getRecordingMedia[0].url;
			},
			getZoomLink () {
				if (this.lesson?.schedule?.endedAt) {
					return false;
				}
				return this.lesson?.schedule?.zoomLink;
			},
			getZoomDownloadLink () {
				return this.lesson?.schedule?.zoomDownload;
			},
			getShowMainCta () {
				if (!this.getZoomLink &&
						!this.getZoomDownloadLink) {
					return false;
				}
				return true;
			},
			getActivities () {
				return this.lesson?.activities;
			},
			getScheduleString () {
				if (!this.lesson.live) {
					return false;
				}
				if (!this.lesson?.schedule?.startsAt) {
					return false;
				}
				const startsAt = this.lesson.schedule.startsAt;
				const startedAt = this.lesson.schedule.startedAt;
				const endedAt = this.lesson.schedule.endedAt;
				if (endedAt) {
					const time = this.$store.getters['auth/getTimeInLocalTimezone']({
						time: endedAt
					});
					return `Ended at ${time}`;
				}
				if (startedAt && !endedAt) {
					return 'Live now';
				}
				const time = this.$store.getters['auth/getTimeInLocalTimezone']({
					time: startsAt
				});
				return `Starts at ${time}`;
			},
			getWillUpload () {
				if (!this.lesson?.schedule?.willUpload) {
					return false;
				}
				if (this.lesson?.schedule?.zoomDownload ||
						this.getVideoSrc) {
					return false;
				}
				return true;
			},
			getAdditionalInfo () {
				return [
					(this.getScheduleString ?
						{
							heading: 'Schedule',
							text: this.getScheduleString
						} : false
					),
					(this.getWillUpload ?
						{
							heading: 'Lesson replay',
							text: 'Teacher will upload a replay'
						} : false
					)
				].filter(Boolean);
			},
			getShowLessonDeals () {
				return this.lesson.deals?.length > 0;
			}
		},
		methods: {
			getDateTime (value) {
				return dayjs(value).format('DD/MM/YYYY HH:mm:ss');
			},
			handleReloadLesson () {
				this.$emit('reloadLesson');
			},

			getGroupsLabels () {
        if (!this.lesson?.activities) {
          return [];
        }

        const groups = this.lesson.activities.reduce((prev, curr) => {
          const prevIdx = prev.length;

          if (curr.type === 'quiz') {
            if (prevIdx === 0 || prev[prevIdx - 1].type !== 'quiz') {
              prev.push({
                type: curr.type
              });
            }
          } else {
            prev.push({
              type: curr.type
            });
          }

          return prev;
        }, []);

        const countedGroups = groups.reduce((prev, curr) => {
          if (prev[curr.type]) {
            prev[curr.type] += 1;
          } else {
            prev[curr.type] = 1;
          }

          return prev;
        }, {});

        const mappedCountedGroups = Object.entries(countedGroups).map((item) => {
          return {
            type: item[0],
            amount: item[1]
          };
        });

        return mappedCountedGroups;
			},

			parseToLessonLabel (label) {
        if (label.type === 'video') {
          return {
            ...label,
            title: `${label.amount} Videos`,
            icon: 'v2-videos'
          };
        }
        if (label.type === 'embeddedPresentation') {
          return {
            ...label,
            title: `${label.amount} Presentations`,
            icon: 'v2-presentation'
          };
        }

        if (label.type === 'deal') {
          return {
            ...label,
            title: `${label.amount} Deals to Play`,
            icon: 'v2-cards'
          };
        }

        if (label.type === 'quiz') {
          return {
            ...label,
            title: `${label.amount} Quizzes`,
            icon: 'v2-quizes'
          };
        }
        if (label.type === 'titlePage') {
          return {
            ...label,
            title: `${label.amount} Handouts`,
            icon: 'v2-pdf'
          };
        }
			}
		}
	};

</script>

<style lang="scss" scoped>
[data-component='video-viewer'] {
  margin:0 0 rem(44);
}
[data-element='main-cta'] {
  display:flex;
  flex-direction: column;
  gap: 8px;
  margin: 12px 0;
  [data-component='btn'] {}
}

[data-component='lesson-action-groups'] {
  margin-top:rem(48);
}

.page {
  &__info {
    gap: 0;
    background: $c-white-l;
    padding: 20px;
    border-radius: 4px;

    &--title {
      font-weight: 700;
    }

    &--subtitle {
      margin-top: 8px;
      font-weight: 500;
    }

    &--lesson {
      @include font(16px, $c-medium-navy, 500, 20px);
    }
  }
}

.lesson {
  @media (max-width: 1279px) {
    display: flex;
    flex-direction: column;

    .main,
    .side {
      width: 100%;
    }
  }

  &__breakdown {
    display: flex;
    flex-direction: column;

    &--title {
      display: flex;
      align-items: center;
      gap: 10px;
      @include font(18px, $c-darkest-navy, 700, 23px);
    }

    &--tags {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      width: 100%;
      color: $c-gray-l2;
      border-radius: 2px;
      padding: 12px;
      gap: 9px;
      background: $c-gray-l2;
      margin-top: 12px;
      margin-bottom: 36px;
      border-radius: 2px;
      @include font(14px, $c-darkest-navy, 400, 18px);

      .tag {
        background: $c-gray-l2;

        svg {
          width: 17px;
          height: 17px;
        }
      }

      &-wrapper {
        display: flex;
        gap: 9px;
      }

      .dot {
        width: 5px;
        height: 5px;
        background: $c-light-navy;
        border-radius: 100px;
        display: flex;
        align-self: center;
        justify-self: center;
      }
    }

    &--controlls {
      display: flex;
      flex-direction: column;
      gap: 6px;
      margin-top: 12px;
      margin-bottom: 36px;

      .separator {
        margin: 6px 0;
      }
    }
  }

  &__activities {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &--title {
      @include font(24px, $c-darkest-navy, 500, 31px);
    }

    &--content {
      display: flex;
      flex-direction: column;
      gap: 16px;

      ::v-deep .activity-card {
        &__title {
          @media (max-width: 1279px) {
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-start;
            gap: 12px;

            &--text {
              width: 100%;
            }

            .progress-wrapper {
              width: 100%;
            }
          }
        }
      }
    }
  }
}
</style>
