<template>
	<div class="activity-card">
		<div class="activity-card__title">
			<div class="activity-card__title--text">
				{{ headTitle }}
			</div>

			<div v-if="isComplete && !isLive" class="activity-card__title--done">
				DONE
				<div class="icon">
					<icon icon="v2-tick" colour="transparent" />
				</div>
			</div>

			<v2-course-progress v-else-if="!isLive" :value="progress" />
		</div>

		<div class="activity-card__body" v-if="activity.type !== 'quiz'">
			<div class="activity-card__body--title">
				<icon icon="v2-video-player" colour="transparent" />
				{{ activity.title }}
			</div>
			<div class="activity-card__body--controll" v-if="!liveUserMode">
				<V2Button
					v-if="isComplete"
					variant="outline"
					fit
					@click="openActivity(activity.item[0].id)"
				>
					Watch again
				</V2Button>
				<V2Button v-else-if="progress === 0" fit @click="openActivity(activity.item[0].id)">
					Start
				</V2Button>
				<V2Button
					v-else
					@click="openActivity(activity.item[0].id)"
					variant="red"
					fit
				>
					Continue
				</V2Button>
				<V2Button v-if="teacherMode" fit @click="editActivity(activity.item[0].id)">
					Edit
				</V2Button>
			</div>
		</div>

		<template v-else>
			<div class="activity-card__body" @click="isActive = !isActive">
				<div class="activity-card__body--title">
					<icon icon="v2-quiz-mini" colour="transparent" />
					{{ activity.title }}
				</div>
				<div class="activity-card__body--controll">
					<V2Button v-if="progress === 0 && !liveUserMode" fit @click="openLastActivity">
						Start
					</V2Button>
					<V2Button
						v-else-if="!liveUserMode"
						variant="red"
						fit
						@click="openLastActivity">
						Continue
					</V2Button>
					<icon icon="v2-chevron-down" colour="transparent" :class="{inactive: !isActive}" />
				</div>
			</div>
			<transition name="slide">
				<div class="activity-card__menu" v-if="isActive">
					<div
						class="activity-card__menu--question"
						v-for="question in activity.item"
						:key="question.id"
						@click.self="openActivity(question.id)"
					>
						{{ question.title }}

						<V2Button
							v-if="teacherMode"
							@click="editActivity(question.id)"
							class="start"
							fit
						>
							Edit
						</V2Button>
					</div>
				</div>
			</transition>

		</template>

	</div>
</template>

<script>
	import Icon             from '@/components/ui/Icon';
	import V2CourseProgress from '@/components/v2/courses/V2CourseProgress.vue';
	import routeParams        from '@/mixins/routeParams';
	import V2Button from '../ui/V2Button.vue';
	import { GAME_MODES } from '@/consts';
	import api from '@/services/api';

	export default {

		components: {
			Icon,
			V2CourseProgress,
			V2Button
		},

		mixins: [routeParams],

		props: {
			activity: {
				type: Object,
				required: true
			},
			liveUserMode: {
				type: Boolean,
				default: false
			},
			isLive: {
				type: Boolean,
				default: false
			},
			teacherMode: {
				type: Boolean,
				default: false
			}
		},

		data () {
			return {
				isActive: false
			};
		},

		computed: {
			headTitle () {
        if (this.activity.type === 'quiz') {
          return `Activity ${this.activity.activityGroupNumb} - ${this.activity.title}`;
        }

        return `Activity ${this.activity.activityGroupNumb} - ${this.activity.name}`;
			},

			isComplete () {
				return this.activity.item.every(({ isComplete }) => isComplete);
			},

			progress () {
				const totalAmount = this.activity.item.length;
				const completedAmount = this.activity.item.reduce((prev, curr) => {
          if (curr.isComplete) {
            prev += 1;
          }
          return prev;
				}, 0);

				return Math.round(completedAmount / totalAmount * 100);
			}
		},

		methods: {
			async openActivity (id) {
        if (this.liveUserMode) {
          return;
        }

        if (this.teacherMode) {
					if (this.activity.type === 'deal') {
						await this.openSimulator(id);
						return;
					}

          await this.$router.push(`${this.getLessonId}/activities/${id}/view`);
          return;
        }

				await this.$router.push(`${this.getLessonId}/activities/${id}`);
			},

			editActivity (id) {
				this.$router.push(`${this.getLessonId}/activities/${id}`);
			},

			openLastActivity () {
				let lastId = this.activity.item.find((act) => !act.isComplete)?.id;

        if (!lastId) {
          lastId = this.activity.item[this.activity.item.length - 1].id;
        }

        if (this.teacherMode) {
          this.$router.push(`${this.getLessonId}/activities/${lastId}/view`);
          return;
        }

				this.$router.push(`${this.getLessonId}/activities/${lastId}`);
			},
			async openSimulator (id) {
				const activity = await api.teaching.getActivityByIdAndLessonId({
					lessonId: this.getLessonId,
					activityId: id
				});

				const deals = activity?.config?.deals || [];
				const dealIds = deals.map(deal => deal.id);
				const startWithEmptyAuction = deals
					.filter(deal => deal.deal.startWithEmptyAuction)
					.map(deal => deal.id);

				const roomToken = await this.$store.dispatch('gameEngine/getRoomToken', {
					mode: GAME_MODES.TEACHER,
					dealIds,
					exitRedirectUrl: window.location.href,
					dealRecordingEnabled: [],
					startWithEmptyAuction
				});
				await this.$store.dispatch('gameEngine/openGameEngine', { roomToken });
			}
		}
	};
</script>

<style lang="scss" scoped>

.slide-enter-active, .slide-leave-active {
  transition: all 0.3s ease;
}

.slide-enter, .slide-leave-to {
  max-height: 0;
  opacity: 0;
  padding: 0;
}

.slide-enter-to, .slide-leave {
  max-height: 1000px;
  opacity: 1;
}

.activity-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 4px;
  padding: 20px;
  background: $c-white-l;
  border: 2px solid $c-dark-navy;

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &--text {
      @include font(22px, $c-dark-navy, 500, 28px);
    }

    &--done {
      display: flex;
      gap: 6px;
      padding: 4px 8px;
      background: $c-gray-l;
      border-radius: 2px;

      @include font(14px, $c-dark-navy, 700, 18px);

      .icon {
        display: flex;
        width: 16px;
        height: 16px;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        background: $c-green;
      }
    }

    .progress-wrapper {
      width: 105px;
    }
  }

  &__body {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $c-gray-l2;
    padding: 16px;
    margin-top: 16px;

    &--title {
      display: flex;
      align-items: center;
      gap: 8px;
      @include font(18px, $c-darkest-navy, 400, 23px);
    }

    &--controll {
      display: flex;
      gap: 10px;
      align-items: center;

      .inactive {
        transform: rotate(0deg);
      }

      .watch-again {
        padding: 8px 20px;
        border-radius: 4px;
        border: 1px solid $c-dark-navy;
        @include font(16px, $c-dark-navy, 500, 20px);
        cursor: pointer;
      }

      .start {
        padding: 8px 20px;
        border-radius: 4px;
        border: 1px solid $c-dark-navy;
        background: $c-dark-navy;
        @include font(16px, $c-white-l, 500, 20px);
        cursor: pointer;
      }

      .continue {
        padding: 8px 20px;
        border-radius: 4px;
        border: 1px solid $c-red;
        background: $c-red;
        cursor: pointer;
        @include font(16px, $c-white-l, 500, 20px);
      }
    }

  }

  &__menu {
    display: flex;
    flex-direction: column;
    gap: 6px;
    transition: all 0.1s ease-in-out;
    height: auto;
    background: $c-bg-white;
    max-height: 100vh;

    &.closed {
      max-height: 0;
      opacity: 0;
    }

    &--question {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 16px;
      @include font(16px, $c-dark-navy, 400, 20px);
      cursor: pointer;
    }
  }

}

</style>
